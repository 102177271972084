<template>
  <div id="My-Momey-Manage">
    <div class="top">
      <div class="left title components">我的基金</div>
    </div>
    <div class="bottom">
      <template v-if="list.datas && 0 < list.datas.length">
        <a-row>
          <a-col
            v-for="(item, i) in list.datas"
            :class="`item-` + i"
            :span="24"
            :key="i"
          >
            <a-button>
              <div>
                <div class="bottom">
                  <a-row>
                    <a-col :span="24">
                      <span>基金名称: </span>
                      <span>{{ item.financial_name }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>投入金额: </span>
                      <span>{{ item.purchase_quota }}</span>
                    </a-col>
                    <!--<a-col :span="24">
                      <span>到期返还: </span>
                      <span>{{ item.revenue }}</span>
                    </a-col> -->
                    <a-col :span="24">
                      <span>收益率: </span>
                      <span>{{ item.monthly_interest_rate }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>购买日期: </span>
                      <span>{{ item.purchase_at }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>基金周期: </span>
                      <span>{{ item.cycle }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>已发放:</span>
                      <span>{{ item.revenue_times }}月</span>
                    </a-col>
                    <a-col :span="24">
                      <span>项目状态: </span>
                      <span>{{ item.status }}</span>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </a-button>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-empty />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "My-Momey-Manage",
  // 数据
  data() {
    return {
      list: {
        datas: [],
        unfoldShow: false,
      },
    };
  },
  // 方法
  methods: {
    async getMyMoneyMangeList() {
      /* TODO 获取我的基金列表 */
      await this.$axios.get("personal-center/financials").then((res) => {
        if (res.code === 0) this.list.datas = res.data;
       // this.list.datas = [{}, {}]
      });
    },
  },
  created() {
    this.getMyMoneyMangeList();
  },
};
</script>

<!-- 添加"scoped"属性，以限制CSS仅对该组件 -->
<style lang="less" scoped>
#My-Momey-Manage {
  > .top {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    > .left {
      > span {
        //: 0.6em;
        color: #dcdcdc;
      }
    }

    > .right {
      //: 0.25rem;
      color: #565e9a;
    }
  }

  > .bottom {
    > .ant-row {
      > .ant-col {
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
        // padding: 0.2rem;
        margin-bottom: 0.2rem;

        > .ant-btn {
          border-radius: 0.2rem;
          background-color: #3d447b;

          > * {
            padding: 0.2rem;

            * {
              text-align: left;
            }

            > .top {
              display: flex;
              justify-content: space-between;

              > p:first-child:not(:last-child) {
                color: rgba(221, 63, 88, 1);
                //: 1.2em;
              }

              > p:last-child:not(:first-child) {
                color: rgb(170, 170, 170);
              }
            }

            > .bottom {
              > .ant-row > .ant-col {
                line-height: 2em;
                display: flex;
                justify-content: space-between;

                > span {
                  &:last-child {
                    text-align: right;
                  }
                }

                > span:first-child {
                  color: #ccc;
                }

                &:last-child {
                  //: 1.1em;
                }
              }
            }
          }
        }
      }

      > .ant-btn {
        height: 2em;
        line-height: 2em;
        background-color: #3d447b;

        &:focus {
          background-color: #3d447b;
        }
      }
    }
  }
}
</style>
